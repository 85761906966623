import queryString from 'query-string';

import { CmsApiSubscribe } from 'modules/cms/api/types/CmsApiSubscribe';
import { ApiSavedSearchUnsaved } from 'modules/userDashboard/api/savedSearch/types/UserDashboardApiSavedSearch';
import {
  generateRedirectUrl,
  saveActionsToTmpStorage,
} from 'utils/temporaryStorage';

type ThirdPartyLoginProvider = 'facebook' | 'google' | 'apple';

type Args = {
  provider: ThirdPartyLoginProvider;
  redirectTo: string | null | undefined;
  inviteId?: string | null | undefined;
  cmsSubscribe?: CmsApiSubscribe;
  savedListing?: { id: string; type: string };
  savedSearch?: ApiSavedSearchUnsaved;
  subscribedSubsite?: string | null | undefined;
};

type ThirdPartyLoginQueryParams = {
  provider: ThirdPartyLoginProvider;
  invite?: string;
  to?: string;
};

export async function getThirdPartyLoginUrl({
  provider,
  redirectTo,
  inviteId,
  cmsSubscribe,
  savedListing,
  savedSearch,
  subscribedSubsite,
}: Args) {
  const queryParams: ThirdPartyLoginQueryParams = { provider };

  if (inviteId) {
    queryParams.invite = inviteId;
  }

  const tmpId = await saveActionsToTmpStorage({
    cmsSubscribe,
    savedListing,
    savedSearch,
    subscribedSubsite,
  });

  if (tmpId !== null) {
    const redirectToWithTmpId = generateRedirectUrl(
      redirectTo ?? `/${CURRENT_LOCALE()}`,
      tmpId,
    );
    queryParams.to = redirectToWithTmpId;
  } else if (redirectTo) {
    queryParams.to = redirectTo;
  }

  return `/third-party-login?${queryString.stringify(queryParams)}`;
}
